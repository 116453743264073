<template>
  <div class="pt-3">
    <Loader v-if="!skillCategories || skillCategories.length == 0" />
    <div class="dashboard__container--body">
<!--       <div class="dashboard__container--body--col">
        <div>
          <h4>Add Skill Category:</h4>
          <div class="mb-3">
            <label for="title">Title</label>
            <input type="text" v-model="newCat.title"></input>
          </div>
          
          <div class="mb-3">
            <button class="btn btn__primary" @click="addCat()">
              Add Category
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
        </div>
      </div> -->
      <div class="dashboard__container--body--col">
        <div>
          <h4 class="mb-3">Job Categories</h4>
        <div class="caption mb-2" v-for="(item, index) in skillCategories" :key="index">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'manageCategories',
  data: () => ({
    newCat: {},
    performingRequest: false,
  }),
  created () {
    this.$store.dispatch("getSkillCategories")   
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'skillCategories']),
  },
  components: {
    Loader,
  },
  methods: {
    async addCat() {
      this.performingRequest = true
      await fb.skillCategoriesCollection.add(this.newCat)
      .then(
        doc => {
          fb.skillCategoriesCollection.doc(doc.id).update({
            id: doc.id
          })
        }
      )
      this.newCat = {}
      this.performingRequest = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearSkillCategories")
  }
}
</script>